window.onload = () => {
  const topNode = document.querySelector('.top');
  const headerWithMenu = document.querySelector('.header');
  const forms = [...(document.querySelectorAll('form') || [])];
  const validationRules = {
    name: /.{2,}/g,
    email: /.*@.*\..*/g,
  }

  /* Header color change */
  document.onscroll = () => {
    const menuButton = topNode.querySelector('.desktop .menuButton.transparent') 
    const topHeight = topNode.clientHeight;
    const headerWithMenuHeight = headerWithMenu.clientHeight;
    const headerHeight = headerWithMenuHeight - topHeight;
    const isBelowHeader = document.documentElement.scrollTop > headerHeight;
    const isWhite =[...topNode.classList].includes('white');

    if (isBelowHeader && !isWhite) {
      topNode.classList.add('white');
      if(menuButton) {
        menuButton.classList.add('dark');
      }
    } else if (!isBelowHeader && isWhite) {
      topNode.classList.remove('white');
      if (menuButton) {
        menuButton.classList.remove('dark');
      }
    }

  }

  /* Forms validation and send */
  forms.forEach((form) => {
    const button = form.getElementsByTagName('button')[0];
    const inputs = form.querySelectorAll('input:not([type=hidden]');
    const textarea = form.querySelector('textarea');
    const api = form.action;

    const isInputValid = (input) => {
      const rule = validationRules[input.name];

      return input.value.match(rule) ? true : false
    };

    const validateForm = () => {
      let isFormValid = [];

      inputs.forEach((item) => {
        const isValid = isInputValid(item);

        if(isValid) {
          isFormValid.push(true);
        } else {
          isFormValid.push(false);
        }
      });

      return isFormValid;
    };

    const updateInputsClasses = () => {
      let isFormValid = [];

      inputs.forEach((item) => {
        const isValid = isInputValid(item);
        const isEmailFilled = item.name === 'email' && item.value.length > 0
        const inputWrapper = item.parentElement.parentElement;

        if (isValid) {
          inputWrapper.classList.remove('invalid');
          isFormValid.push(true);
        } else {
          button.classList.add('invalid');
          inputWrapper.classList.add('invalid');
          isFormValid.push(false);
        }
        
        if (isEmailFilled) {
          inputWrapper.classList.add('filled');
        } else {
          inputWrapper.classList.remove('filled');
        }
      });

      const isAllInputsFilled = isFormValid.filter((status) => !status).length === 1 && form.querySelector('.email.invalid.filled');

      if (!isAllInputsFilled) {
        form.classList.add('invalid');
      }
    };

    button.onclick = (e) => {
      e.preventDefault();
      
      const formData = [...(new FormData(form).entries())];
      const dataObject = formData.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});

      if (validateForm().includes(false)) {
        updateInputsClasses();

        return;
      }

      [...inputs, textarea].forEach((input) => {
        if (input) {
          input.value = ''
        }
      });

      const buttonText = button.innerText;
      button.innerText = 'Success';
      button.classList.add('success');

      fetch(api, {
        method: 'post',
        body: JSON.stringify(dataObject),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(() => {
        setTimeout(() => {
          button.innerText = buttonText;
          button.classList.remove('success');
        }, 1600);
      });

    };

    inputs.forEach((input) => {
      const inputWrapper = input.parentElement.parentElement;

      input.onkeyup = () => {
        if (!button.classList.contains('invalid')) {
          return;
        }

        const inputsValidationStatus = validateForm();
        const isAllInputsValid = !inputsValidationStatus.includes(false);
        const isAllInputsFilled = isAllInputsValid || 
          (inputsValidationStatus.filter((status) => !status).length === 1 && form.querySelector('.email.invalid.filled'));

        inputWrapper.classList.remove('invalid', 'filled');
        form.classList.remove('invalid');
        form.classList.add('half');

        if (isAllInputsFilled) {
          form.classList.remove('half');
        }

        if (isAllInputsValid) {
          button.classList.remove('invalid');
        } else {
          updateInputsClasses();
        }
      }
    })
  });
}